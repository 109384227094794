@use '@angular/material' as mat;

@import '~handsontable/dist/handsontable.full.min.css';

@import '../variables/variables';
@import '../mixins/mixins';

@include mat.core;

$elias-app-theme: mat.define-light-theme($elias-app-primary, $elias-app-accent, $elias-app-warn);

@include mat.all-component-themes($elias-app-theme);

button[mat-stroked-button] {
    border-radius: 23.5px;
    color: $gray;
    border-color: $gray;
    font-family: $font-work-poppins;

    &.mat-accent {
        border-color: $primary-green;
        color: $primary-green;

        &:hover {
            background-color: mat.get-color-from-palette($elias-app-primary-green, 700);
            color: white;
        }
    }

    &.mat-warn {
        border-color: $danger;
    }
}

button[mat-button] {
    border-radius: 23.5px;
    color: $gray;
    font-family: $font-work-poppins;
}

button[mat-flat-button],
button[mat-raised-button] {
    background-color: $primary-green;
    border-color: $primary-green;
    border-radius: 23.5px;
    box-shadow: none;
    font-family: $font-work-poppins;

    &.mat-accent {
        background-color: $primary-green;
        border-color: $primary-green;
    }

    // TODO: Remove after upgrade to Angular 15
    &:hover {
        background-color: mat.get-color-from-palette($elias-app-primary-green, 700);
    }
}

.mat-stroked-button:not([class*='mat-elevation-z']),
.mat-flat-button:not([class*='mat-elevation-z']) {
    box-shadow: none;
}

.mat-slide-toggle {
    &.channel-slide-toggle,
    &.preview-toggle {
        .mat-slide-toggle-thumb {
            height: 13px;
            width: 13px;
            margin-top: 3px;
        }

        .mat-slide-toggle-bar {
            width: 30px;
        }
    }

    &.mat-checked {
        .mat-slide-toggle-thumb {
            background-color: white;
        }

        .mat-slide-toggle-bar {
            background-color: $primary-green;
        }
    }
}

.mat-radio-group {
    &.error-circle {
        .mat-radio-outer-circle {
            border-color: #d60050 !important;
        }
    }

    .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
        border-color: $primary-green;
    }

    .mat-radio-button.mat-primary .mat-radio-inner-circle {
        background-color: $primary-green;
    }
}

.mat-list-base .mat-list-item .mat-list-item-content {
    width: 100%;
}

.mat-grid-tile .mat-figure {
    display: block !important;
}

.alignment {
    .mat-radio-label-content {
        display: flex;
        align-items: center;
    }
}

.mat-dialog-container {
    padding: 0 !important;
}

.mat-chip {
    transition: none !important;
    box-shadow: none !important;
    cursor: pointer !important;
}

.mat-standard-chip:hover::after {
    opacity: 0 !important;
}

.mat-standard-chip:focus::after {
    opacity: 0 !important;
}

.mat-chip.mat-standard-chip .mat-chip-remove {
    opacity: 1;
    color: white;
}

/* Helps to close the expansion panel when loaded in the component
  Present Angular Issue
  https://github.com/angular/components/issues/13870
*/
.ng-animating div mat-accordion mat-expansion-panel mat-expansion-panel-header {
    height: 64px !important;
}

.ng-animating div mat-accordion mat-expansion-panel div.mat-expansion-panel-content {
    height: 0 !important;
    visibility: hidden !important;
}

.mat-expansion-panel-header {
    height: 64px !important;
}

.mat-form-field,
.mat-checkbox,
.mat-radio-button,
.mat-option,
.mat-select {
    font-family: $font-work-poppins;
}

.mat-optgroup {
    .mat-optgroup-label {
        font-family: $font-work-poppins;
    }

    .mat-option:not(.mat-option-multiple) {
        padding-left: 16px !important;
    }
}

.mat-autocomplete-panel {
    .mat-option-text {
        color: $primary-dark-grey !important;
    }
}

.mat-form-field-type-mat-select {
    .mat-select-value {
        .mat-select-value-text {
            line-height: 1.23 !important;
        }
    }

    .mat-form-field-flex {
        height: 57px;
    }

    &.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
        transform: unset;
    }
}

.color-picker {
    .btn-picker {
        width: 49px !important;
        height: 49px !important;
        margin-top: 5px;
        border-radius: 8px;

        .transparent {
            width: 45px !important;
            margin-left: 7px;
        }
    }
}

.mat-checkbox {
    &.mat-checkbox-checked.mat-accent,
    &.mat-checkbox-checked.mat-primary {
        .mat-checkbox-background {
            background-color: $primary-green;
        }
    }
}

.mat-pseudo-checkbox {
    &.mat-pseudo-checkbox-checked {
        background-color: $primary-green;
    }
}

.mat-icon-button {
    .mat-icon {
        &:hover {
            color: $primary-green;
        }
    }
}

.mat-form-field-appearance-outline {
    .mat-form-field-outline-thick {
        .mat-form-field-outline-start,
        .mat-form-field-outline-end,
        .mat-form-field-outline-gap {
            border-width: 1px !important;
        }
    }
}

tr.mat-row {
    height: 64px !important;
}

.mat-menu-panel {
    max-width: none !important;
}

.menu-button-icon {
    i::before {
        margin-right: 0;
    }
}

.mat-button-toggle-group {
    border-radius: 40px !important;
}

.mat-button-toggle {
    background-color: $primary-green;
    color: white;
}

.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
    background-color: $primary-green;
}

.mat-spinner circle {
    stroke: $primary-green;
}

.has-prefix {
    .mat-form-field-prefix {
        top: -2px !important;
    }

    &.mat-form-field-hide-placeholder {
        .mat-form-field-prefix {
            top: -6px !important;
        }
    }
}

/* .select-overlay .cdk-overlay-pane {
  !* Move dropdown options below select component
    if any css position error occurs just adjust this css accordingly *!
  transform: translateX(-9px) translateY(30px) !important;
} */

.mat-form-field-type-mat-input .mat-form-field-subscript-wrapper {
    overflow: unset;
}

@import './override';

/* material-icons-regular - latin */
@font-face {
    font-display: swap;
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/material-icons-v143-latin-regular.woff2') format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
}

/* merriweather-700 - latin */
@font-face {
    font-display: swap;
    font-family: Merriweather;
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/merriweather-v30-latin-700.woff2') format('woff2');
}

/* poppins-regular - latin */
@font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/poppins-v22-latin-regular.woff2') format('woff2');
}

/* poppins-500 - latin */
@font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    src: url('/assets/fonts/poppins-v22-latin-500.woff2') format('woff2');
}

/* poppins-600 - latin */
@font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/poppins-v22-latin-600.woff2') format('woff2');
}

/* poppins-700 - latin */
@font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/poppins-v22-latin-700.woff2') format('woff2');
}

/* poppins-900 - latin */
@font-face {
    font-display: swap;
    font-family: Poppins;
    font-style: normal;
    font-weight: 900;
    src: url('/assets/fonts/poppins-v22-latin-900.woff2') format('woff2');
}
